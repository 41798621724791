<template>
<div id="reports">

  <v-row>
    <v-col cols="auto" v-for="(r, index) in reports" :key="index">
      <v-card @click="changeRoute(r.route)" outlined :style="`border: 1px solid ${r.color};border-left: 6px solid ${r.color}`">
        <v-card-title :style="`color:${r.color}`"><b>{{r.title}}</b></v-card-title>
        <v-card-text>{{r.description}}</v-card-text>
      </v-card>
    </v-col>
  </v-row>

</div>
</template>

<script>
export default {
  name: "Reports",
  data(){
    return{
      reports: [
        {title: 'S.O REPORT', route: '/soReport', color:'#cc5500',
          description:'See Sales Officer Wise Sales Report'},
        {title: 'DISTRIBUTOR REPORT', route: '/distributorReport', color:'teal',
          description:'See Distributor Wise Sales Report'}
      ]
    }
  },
  methods:{
    changeRoute(route) {
      this.$router.push(route)
    },
  }
}
</script>

<style scoped>
#reports{
  padding: 10px 30px;
}
</style>